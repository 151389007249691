import api from '../../../services/api';

export const searchContractNotifications = (enrollment_id) => {
  return (dispatch) => {
    api
      .get('/digitalContract/findNotifications', {
        params: { enrollment_id },
      })
      .then((response) => {
        // if (response.data) {
        dispatch({
          type: 'SHOW_CONTRACT_NOTIFICATIONS',
          payload: response.data,
        });
        // }
      });
  };
};
