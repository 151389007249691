import { PURGE } from 'redux-persist';

const initialState = {
  token: '',
  user: {},
  userRole: 'admin',
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_WITH_JWT': {
      return { ...state, ...action.payload };
    }
    case 'LOGOUT_WITH_JWT': {
      return { ...state, ...action.payload };
    }
    case 'CHANGE_ROLE': {
      return { ...state, userRole: action.userRole };
    }
    case PURGE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
