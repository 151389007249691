import { combineReducers } from 'redux';
import customizer from './customizer';
import auth from './auth';
import navbar from './navbar/Index';
import enrollment from './enrollment';
import notification from './notification';

const rootReducer = combineReducers({
  customizer,
  auth,
  navbar,
  enrollment,
  notification,
});

export default rootReducer;
