import React from 'react';
import * as Icon from 'react-feather';

const horizontalMenuConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/home',
  },
  {
    id: 'classHistory',
    title: 'Histórico de Aulas',
    type: 'item',
    icon: <Icon.Calendar size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/classSchedule',
  },
  {
    id: 'grades',
    title: 'Notas',
    type: 'item',
    icon: <Icon.Book size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/grades',
  },
  {
    id: 'books',
    title: 'E-Book',
    type: 'item',
    icon: <Icon.BookOpen size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/ebook',
  },
  {
    id: 'digitalContract',
    title: 'Contrato',
    type: 'item',
    icon: <Icon.Clipboard size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/digitalContract',
  },
  {
    id: 'receivable',
    title: 'Contas',
    type: 'item',
    icon: <Icon.DollarSign size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/receivables',
  },

  {
    id: 'configuration',
    title: 'Configurações',
    type: 'item',
    icon: <Icon.Tool size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/configuration',
  },
];

export default horizontalMenuConfig;
