import { produce } from 'immer';
import { PURGE } from 'redux-persist';

const INITIAL_STATE = {
  enrollment: {},
};

export default function enrollmentReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'CHANGE_ENROLLMENT': {
        draft.enrollment = action.payload;
        break;
      }
      case PURGE: {
        draft.enrollment = INITIAL_STATE;
        break;
      }

      default:
    }
  });
}
