import React from 'react';
import logo from '../../../assets/img/logo/logoVsm.png';
import '../../../assets/scss/components/app-loader.scss';

class SpinnerComponent extends React.Component {
  render() {
    return (
      <div className="fallback-spinner vh-100">
        <img
          className="fallback-logo"
          style={{ maxWidth: '60px' }}
          src={logo}
          alt="logo"
        />
        <div className="loading">
          <div className="effect-1 effects" />
          <div className="effect-2 effects" />
          <div className="effect-3 effects" />
        </div>
      </div>
    );
  }
}

export default SpinnerComponent;
