import styled from 'styled-components';

export const NavbarEnrollments = styled.div`
  width: 355px;
  position: absolute;
  left: 55px;
  font-size: 12px;
`;

export const NavbarMobileEnrollments = styled.div`
  width: 170px;
  position: absolute;
  left: 55px;
  font-size: 10px;
`;
