import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar } from 'reactstrap';
import Select from 'react-select';
import classnames from 'classnames';
import NavbarBookmarks from './NavbarBookmarks';
import NavbarUser from './NavbarUser';
import userImg from '../../../assets/img/portrait/small/avatar-s-generic.png';
import logoVsm from '../../../assets/img/logo/logoVsm.png';

import api from '../../../services/api';

import { NavbarEnrollments, NavbarMobileEnrollments } from './styles';
import { changeEnrollment } from '../../../redux/actions/enrollment';
import { searchContractNotifications } from '../../../redux/actions/notification';

const ThemeNavbar = ({
  navbarColor,
  navbarType,
  horizontal,
  scrolling,
  sidebarVisibility,
  handleAppOverlay,
  changeCurrentLang,
}) => {
  const dispatch = useDispatch();

  const selectedEnrollment = useSelector(
    (state) => state.enrollment.enrollment
  );

  const [studentEnrollmentsSelect, setStudentEnrollmentsSelect] = useState([
    { value: '', label: 'Selecione' },
  ]);

  const [selectedEnrollmentId, setSelectedEnrollmentId] = useState(
    selectedEnrollment?.id
  );

  const [studentEnrollments, setStudentEnrollments] = useState([]);

  useEffect(() => {
    api.get('/enrollments/myEnrollments').then((response) => {
      setStudentEnrollments(response.data);
      const enrollmentsSelect = response.data.map((enrollment) => {
        const initialHourSplit = enrollment.class.hour.initialHour.split(':');
        const finalHourSplit = enrollment.class.hour.finalHour.split(':');

        const labelEnrollment = `${enrollment.class.course.name} - ${
          enrollment.class.name
        } ${`${initialHourSplit[0]}:${initialHourSplit[1]}`}-${`${finalHourSplit[0]}:${finalHourSplit[1]}`}`;
        return { value: enrollment.id, label: labelEnrollment };
      });

      // if (!selectedEnrollmentId && enrollmentsSelect.length > 0) {
      //   setSelectedEnrollmentId(enrollmentsSelect[0].value);
      // }

      setStudentEnrollmentsSelect(enrollmentsSelect);
    });
  }, []);

  const handleEnrollmentChange = useCallback((event) => {
    setSelectedEnrollmentId(event.value);
  }, []);

  useEffect(() => {
    if (selectedEnrollmentId) {
      const enrollment = studentEnrollments.find(
        (enroll) => enroll.id === selectedEnrollmentId
      );

      dispatch(changeEnrollment(enrollment));
      dispatch(searchContractNotifications(selectedEnrollmentId));
    } else if (!selectedEnrollmentId && studentEnrollmentsSelect.length > 0) {
      setSelectedEnrollmentId(studentEnrollmentsSelect[0].value);
    }
  }, [
    dispatch,
    studentEnrollments,
    selectedEnrollmentId,
    studentEnrollmentsSelect,
  ]);

  const colorsArr = ['primary', 'danger', 'success', 'info', 'warning', 'dark'];
  const navbarTypes = ['floating', 'static', 'sticky', 'hidden'];
  return (
    <>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          'header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow',
          {
            'navbar-light':
              navbarColor === 'default' || !colorsArr.includes(navbarColor),
            'navbar-dark': colorsArr.includes(navbarColor),
            'bg-primary': navbarColor === 'primary' && navbarType !== 'static',
            'bg-danger': navbarColor === 'danger' && navbarType !== 'static',
            'bg-success': navbarColor === 'success' && navbarType !== 'static',
            'bg-info': navbarColor === 'info' && navbarType !== 'static',
            'bg-warning': navbarColor === 'warning' && navbarType !== 'static',
            'bg-dark': navbarColor === 'dark' && navbarType !== 'static',
            'd-none': navbarType === 'hidden' && !horizontal,
            'floating-nav':
              (navbarType === 'floating' && !horizontal) ||
              (!navbarTypes.includes(navbarType) && !horizontal),
            'navbar-static-top': navbarType === 'static' && !horizontal,
            'fixed-top': navbarType === 'sticky' || horizontal,
            scrolling: horizontal && scrolling,
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <NavbarBookmarks
                  sidebarVisibility={sidebarVisibility}
                  handleAppOverlay={handleAppOverlay}
                />
              </div>
              {horizontal ? (
                <>
                  <NavbarEnrollments>
                    <Select
                      className="React"
                      classNamePrefix="select"
                      value={
                        studentEnrollmentsSelect.find(
                          (selectValue) =>
                            Number(selectValue.value) === selectedEnrollmentId
                        ) || null
                      }
                      name="color"
                      placeholder="Selecione a Turma para Visualizar"
                      onChange={handleEnrollmentChange}
                      options={studentEnrollmentsSelect}
                    />
                  </NavbarEnrollments>
                  <div className="logo d-flex align-items-center">
                    <img className="brand-logo" src={logoVsm} alt="vsm" />
                  </div>
                </>
              ) : null}

              {!horizontal ? (
                <>
                  <NavbarMobileEnrollments>
                    <Select
                      className="React"
                      classNamePrefix="select"
                      value={
                        studentEnrollmentsSelect.find(
                          (selectValue) =>
                            Number(selectValue.value) === selectedEnrollmentId
                        ) || null
                      }
                      name="color"
                      placeholder="Selecione a Turma para Visualizar"
                      onChange={handleEnrollmentChange}
                      options={studentEnrollmentsSelect}
                    />
                  </NavbarMobileEnrollments>
                </>
              ) : null}
              <NavbarUser
                handleAppOverlay={handleAppOverlay}
                changeCurrentLang={changeCurrentLang}
                userName="John Doe"
                userImg={userImg}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default ThemeNavbar;
