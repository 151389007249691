import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as Icon from 'react-feather';
import authActions from '../../../redux/actions/auth';

const NavbarUser = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(authActions.logoutWithJWT());
  };

  const { contractNotifications, user } = useSelector((state) => ({
    contractNotifications: state.notification.contractNotifications,
    user: state.auth.login.user,
  }));

  return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      <UncontrolledDropdown
        style={{ display: 'absolute', left: '8px' }}
        tag="li"
        className="dropdown-notification nav-item"
      >
        <DropdownToggle tag="a" className="nav-link nav-link-label">
          <Icon.Bell size={21} />
          <Badge pill color="primary" className="badge-up">
            {' '}
            {contractNotifications}
          </Badge>
        </DropdownToggle>
        <DropdownMenu tag="ul" right className="dropdown-menu-media">
          <li className="dropdown-menu-header">
            <div className="dropdown-header mt-0">
              <span className="notification-title">Notificações</span>
            </div>
          </li>
          <PerfectScrollbar
            className="media-list overflow-hidden position-relative"
            options={{
              wheelPropagation: false,
            }}
          >
            <div className="d-flex justify-content-between">
              <Media className="d-flex align-items-start">
                {contractNotifications > 0 ? (
                  <>
                    <Media left href="#">
                      <Icon.PlusSquare
                        className="font-medium-5 primary"
                        size={21}
                      />
                    </Media>
                    <Media
                      body
                      onClick={() => {
                        history.push('/digitalContract');
                      }}
                    >
                      <Media heading className="primary media-heading" tag="h6">
                        Você tem um novo contrato para assinar!
                      </Media>
                      <p className="notification-text">
                        Acesse a área de contratos para realizar a assinatura
                      </p>
                    </Media>
                  </>
                ) : (
                  <>
                    <Media left href="#">
                      <Icon.XCircle
                        className="font-medium-5 danger"
                        size={21}
                      />
                    </Media>
                    <Media body>
                      <Media heading className="danger media-heading" tag="h6">
                        Sem novas notificações!
                      </Media>
                    </Media>
                  </>
                )}
              </Media>
            </div>
          </PerfectScrollbar>
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle
          tag="a"
          className="nav-link dropdown-user-link d-flex align-items-baseline"
        >
          <div className="user-nav d-sm-flex d-none ">
            <span className="user-name text-bold-600">{user.name}</span>
            <span className="user-status">{user.ra}</span>
          </div>
          <span data-tour="user">
            <img
              src={props.userImg}
              className="round"
              height="40"
              width="40"
              alt="avatar"
            />
          </span>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag="a" href="#" onClick={handleLogout}>
            <Icon.Power size={14} className="mr-50" />
            <span className="align-middle">Sair do portal</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </ul>
  );
};
export default NavbarUser;
