import React, { Suspense, lazy } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { Layout } from './utility/context/Layout';
// import * as serviceWorker from './serviceWorker';
import { store, persistor } from './redux/storeConfig/store';
import Spinner from './components/@vuexy/spinner/Fallback-spinner';
import './index.scss';
import './@fake-db';
import 'react-toastify/dist/ReactToastify.css';
import api from './services/api';
import apiVSM from './services/apiVSM';



const LazyApp = lazy(() => import('./App'));

const onBeforeLift = () => {
  const token = localStorage.getItem('@VSM:token');
  api.defaults.headers.authorization = `Bearer ${token}`;
  apiVSM.defaults.headers.authorization = `Bearer ${token}`;
};

// configureDatabase()
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} onBeforeLift={onBeforeLift}>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <LazyApp />
        </Layout>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
