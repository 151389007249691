import { toast } from 'react-toastify';
import { persistor } from '../../storeConfig/store';
import { history } from '../../../history';

import api from '../../../services/api';
import apiVSM from '../../../services/apiVSM';

export const changeRole = (role) => {
  return (dispatch) => dispatch({ type: 'CHANGE_ROLE', userRole: role });
};

export const loginWithJWT = (user) => {
  return (dispatch) => {
    api
      .post('/sessions', {
        ra: user.ra,
        password: user.password,
      })
      .then((response) => {
        if (response.data) {
          const { token, user: userLogin } = response.data;

          localStorage.setItem('@VSM:token', token);

          api.defaults.headers.authorization = `Bearer ${token}`;
          apiVSM.defaults.headers.authorization = `Bearer ${token}`;

          dispatch({
            type: 'LOGIN_WITH_JWT',
            payload: { token, user: userLogin, loggedInWith: 'jwt' },
          });

          history.push('/home');
        }
      })
      .catch((err) => {
        toast.error('Usuário ou senha inválidos');
      });
  };
};

export const logoutWithJWT = () => {
  return (dispatch) => {
    localStorage.removeItem('@VSM:token');

    persistor.purge().then(() => {
      dispatch({
        type: 'LOGOUT_WITH_JWT',
        payload: { user: {}, token: null },
      });
    });

    // history.push('/');
  };
};
