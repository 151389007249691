import { produce } from 'immer';

const INITIAL_STATE = {
  contractNotifications: 0,
};

export default function notificationsReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SHOW_CONTRACT_NOTIFICATIONS': {
        draft.contractNotifications = action.payload;
        break;
      }

      default:
    }
  });
}
